package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentDealershipId
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.nowLocal
import kotlin.time.Duration.Companion.days
import kotlinx.datetime.*

fun LocalDate.nextOrCurrentWeekday(day: DayOfWeek): LocalDate {
    var current = this
    while (current.dayOfWeek != day) current += DatePeriod(days = 1)
    return current
}
fun LocalDate.previousOrCurrentWeekday(day: DayOfWeek): LocalDate {
    var current = this
    while (current.dayOfWeek != day) current -= DatePeriod(days = 1)
    return current
}

val Vehicle.sellingDate: LocalDate?
    get() = submitted?.plus(1.days)?.toLocalDateTime(TimeZone.currentSystemDefault())?.date?.nextOrCurrentWeekday(DayOfWeek.FRIDAY)

val Vehicle.sellingDateString: String?
    get() {
        return sellingDate?.let {
            if ((it - nowLocal().date).days >= 1)
                "on ${it.renderToString(RenderSize.Abbreviation)}"
            else
                "today"
        }
    }

val Vehicle.statusDescription get() = shared{
    completion?.let {
        if(it.sold) {
            if(currentDealershipId() == it.winner)
                Strings.purchasedAtX(it.price.renderPriceInDollars())
            else  if(currentDealershipId() == seller)
                Strings.soldForX(it.price.renderPriceInDollars())
            else
                Strings.soldAtX(it.price.renderPriceInDollars())
        } else if(it.bids == 0) {
            Strings.noBids
        } else counterOffer?.let { counter ->
            if(cancelled == null) {
                if(currentDealershipId() == it.winner)
                    Strings.receivedCounterOfferOfX(counter.renderPriceInDollars())
                else  if(currentDealershipId() == seller)
                    Strings.counteredWithX(counter.renderPriceInDollars())
                else
                    Strings.highBidOfX(it.price.renderPriceInDollars())
            } else {
                if(currentDealershipId() == it.winner)
                    Strings.declinedCounterOfferOfX(counter.renderPriceInDollars())
                else  if(currentDealershipId() == seller)
                    Strings.counterOfferOfXDeclined(counter.renderPriceInDollars())
                else
                    Strings.highBidOfX(it.price.renderPriceInDollars())
            }
        } ?: run {
            if(cancelled == null) {
                if(currentDealershipId() == it.winner)
                    Strings.youOfferedXWaitingOnSeller(it.price.renderPriceInDollars())
                else  if(currentDealershipId() == seller)
                    Strings.openOfferOfX(it.price.renderPriceInDollars())
                else
                    Strings.highBidOfX(it.price.renderPriceInDollars())
            } else {
                when (cancellationReason) {
                    CancellationReason.CANCELLED_BY_SELLER, CancellationReason.CANCELLED_BY_BUYER -> Strings.dealCancelled
                    CancellationReason.OFFER_EXPIRED, CancellationReason.OFFER_DECLINED -> {
                        if (currentDealershipId() == it.winner)
                            Strings.offerOfXDeclined(it.price.renderPriceInDollars())
                        else if(currentDealershipId() == seller)
                            Strings.declinedOfferOfX(it.price.renderPriceInDollars())
                        else
                            Strings.highBidOfX(it.price.renderPriceInDollars())
                    }
                    else -> Strings.highBidOfX(it.price.renderPriceInDollars())
                }
            }
        }
    } ?: liveAt?.let {
        Strings.liveNow
    } ?: submitted?.let {
        val relationship =
            currentSessionNullable.awaitNotNull().vehicleRelationships.watch(
                DealershipVehiclePair(
                    currentDealershipId.await() ?: nullUuid, _id
                )
            )
        relationship()?.autobid?.let {
            Strings.proxyBidUpToX(it.renderPriceInDollars())
        } ?: Strings.auctionStartsX(sellingDateString)
    } ?: Strings.draft
}