// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.auth.oauth

import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlinx.datetime.Instant
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareOauthClientSecretFields() {
    val props: Array<SerializableProperty<OauthClientSecret, *>> = arrayOf(OauthClientSecret_createdAt, OauthClientSecret_masked, OauthClientSecret_secretHash, OauthClientSecret_disabledAt)
    OauthClientSecret.serializer().properties { props }
}
val <K> DataClassPath<K, OauthClientSecret>.createdAt: DataClassPath<K, Instant> get() = this[OauthClientSecret_createdAt]
val <K> DataClassPath<K, OauthClientSecret>.masked: DataClassPath<K, String> get() = this[OauthClientSecret_masked]
val <K> DataClassPath<K, OauthClientSecret>.secretHash: DataClassPath<K, String> get() = this[OauthClientSecret_secretHash]
val <K> DataClassPath<K, OauthClientSecret>.disabledAt: DataClassPath<K, Instant?> get() = this[OauthClientSecret_disabledAt]
inline val OauthClientSecret.Companion.path: DataClassPath<OauthClientSecret, OauthClientSecret> get() = path<OauthClientSecret>()


object OauthClientSecret_createdAt: SerializableProperty<OauthClientSecret, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: OauthClientSecret): Instant = receiver.createdAt
    override fun setCopy(receiver: OauthClientSecret, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = OauthClientSecret.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = now()
}
object OauthClientSecret_masked: SerializableProperty<OauthClientSecret, String> {
    override val name: String = "masked"
    override fun get(receiver: OauthClientSecret): String = receiver.masked
    override fun setCopy(receiver: OauthClientSecret, value: String) = receiver.copy(masked = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = OauthClientSecret.serializer().tryFindAnnotations("masked")
}
object OauthClientSecret_secretHash: SerializableProperty<OauthClientSecret, String> {
    override val name: String = "secretHash"
    override fun get(receiver: OauthClientSecret): String = receiver.secretHash
    override fun setCopy(receiver: OauthClientSecret, value: String) = receiver.copy(secretHash = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = OauthClientSecret.serializer().tryFindAnnotations("secretHash")
}
object OauthClientSecret_disabledAt: SerializableProperty<OauthClientSecret, Instant?> {
    override val name: String = "disabledAt"
    override fun get(receiver: OauthClientSecret): Instant? = receiver.disabledAt
    override fun setCopy(receiver: OauthClientSecret, value: Instant?) = receiver.copy(disabledAt = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = OauthClientSecret.serializer().tryFindAnnotations("disabledAt")
    override val default: Instant? = null
}
