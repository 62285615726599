package com.ilussobsa.utils

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.lightningkite.kiteui.models.*

val Icon.Companion.clean get() = Icon(1.5.rem, 1.5.rem, 0, 0, 24, 24, listOf(
    "M19,9L20.25,6.25L23,5L20.25,3.75L19,1L17.75,3.75L15,5L17.75,6.25Z",
            "M19,15L17.75,17.75L15,19L17.75,20.25L19,23L20.25,20.25L23,19L20.25,17.75Z",
            "M11.5,9.5L9,4L6.5,9.5L1,12l5.5,2.5L9,20l2.5-5.5L17,12L11.5,9.5z M9.99,12.99L9,15.17l-0.99-2.18L5.83,12l2.18-0.99 L9,8.83l0.99,2.18L12.17,12L9.99,12.99z",
))

val Icon.Companion.gavel get() = Icon(1.5.rem, 1.5.rem, 0, 0, 126, 123, listOf("M97.48,26.61L97.48,26.61c3.09,3.09,3.15,8.08,0.14,11.1l-1.63,1.63l13.1,12.88L75.96,84.14L63.2,70.92 l-49.74,49.74c-3.01,3.01-8.01,2.95-11.1-0.14l0,0c-3.09-3.09-3.15-8.08-0.14-11.1l49.92-49.92l-9.99-10.39l31.32-31.93l11.21,11 l1.72-1.72C89.4,23.46,94.39,23.52,97.48,26.61L97.48,26.61z M72.11,1.88L72.11,1.88c2.46,2.46,2.51,6.43,0.11,8.83L35.69,47.24 c-2.4,2.4-6.37,2.35-8.83-0.11l0,0c-2.46-2.46-2.51-6.43-0.11-8.83L63.28,1.77C65.68-0.63,69.65-0.58,72.11,1.88L72.11,1.88z M124.04,53.81L124.04,53.81c2.46,2.46,2.51,6.43,0.11,8.83L87.62,99.18c-2.4,2.4-6.37,2.35-8.83-0.11l0,0 c-2.46-2.46-2.51-6.43-0.11-8.83l36.53-36.53C117.61,51.3,121.58,51.35,124.04,53.81L124.04,53.81z"))
val Icon.Companion.publish get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M440-160v-326L336-382l-56-58 200-200 200 200-56 58-104-104v326h-80ZM160-600v-120q0-33 23.5-56.5T240-800h480q33 0 56.5 23.5T800-720v120h-80v-120H240v120h-80Z"))
val Icon.Companion.edit get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"))
val Icon.Companion.transport get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"))
val Icon.Companion.missingImage get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"))
val Icon.Companion.priorAccident get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M720-520q-83 0-141.5-58.5T520-720q0-82 58-141t142-59q83 0 141.5 58.5T920-720q0 83-58.5 141.5T720-520Zm-20-160h40v-160h-40v160ZM160-160q-17 0-28.5-11.5T120-200v-320l84-240q6-18 21.5-29t34.5-11h192q-6 19-9 39t-3 41H274l-42 120h235q11 23 25.5 43t32.5 37H200v200h560v-123q21-3 41-9t39-15v267q0 17-11.5 28.5T800-160h-40q-17 0-28.5-11.5T720-200v-40H240v40q0 17-11.5 28.5T200-160h-40Zm560-440q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM300-360q25 0 42.5-17.5T360-420q0-25-17.5-42.5T300-480q-25 0-42.5 17.5T240-420q0 25 17.5 42.5T300-360Zm360 0q25 0 42.5-17.5T720-420q0-5-.5-10t-2.5-10q-27 0-52.5-5T616-460q-8 8-12 18.5t-4 21.5q0 25 17.5 42.5T660-360Zm-460 40v-200 200Z"))
val Icon.Companion.paintwork get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M440-80q-33 0-56.5-23.5T360-160v-160H240q-33 0-56.5-23.5T160-400v-280q0-66 47-113t113-47h480v440q0 33-23.5 56.5T720-320H600v160q0 33-23.5 56.5T520-80h-80ZM240-560h480v-200h-40v160h-80v-160h-40v80h-80v-80H320q-33 0-56.5 23.5T240-680v120Zm0 160h480v-80H240v80Zm0 0v-80 80Z"))
val Icon.Companion.towing get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M40-280v-160h328L120-636v116H40v-240h40l440 242v-282h200l200 240v280H820q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35q-50 0-85-35t-35-85H40Zm200 60q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm460 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM600-560h216L682-720h-82v160Z"))
val Icon.Companion.damage get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-80q-33 0-56.5-23.5T120-160v-160q0-33 23.5-56.5T200-400h560q33 0 56.5 23.5T840-320v160q0 33-23.5 56.5T760-80H200Zm0-80h560v-160H200v160Zm61-300L60-574l240-40-65-235 199 141 120-212 40 240 235-65-141 199 152 86H678l-106-60 62-88-104 29-18-106-52 93-88-62 29 104-106 18 120 72H261Zm226 0Zm-7 220Z"))
val Icon.Companion.waterDrop get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M491-200q12-1 20.5-9.5T520-230q0-14-9-22.5t-23-7.5q-41 3-87-22.5T343-375q-2-11-10.5-18t-19.5-7q-14 0-23 10.5t-6 24.5q17 91 80 130t127 35ZM480-80q-137 0-228.5-94T160-408q0-100 79.5-217.5T480-880q161 137 240.5 254.5T800-408q0 140-91.5 234T480-80Zm0-80q104 0 172-70.5T720-408q0-73-60.5-165T480-774Q361-665 300.5-573T240-408q0 107 68 177.5T480-160Zm0-320Z"))
val Icon.Companion.money get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.taxi get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M160-80v-240h120v240H160Zm200 0v-476q-50 17-65 62.5T280-400h-80q0-128 75-204t205-76q100 0 150-49.5T680-880h80q0 88-37.5 157.5T600-624v544h-80v-240h-80v240h-80Zm120-640q-33 0-56.5-23.5T400-800q0-33 23.5-56.5T480-880q33 0 56.5 23.5T560-800q0 33-23.5 56.5T480-720Z"))
val Icon.Companion.acIssues get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M800-560q-17 0-28.5-11.5T760-600q0-17 11.5-28.5T800-640q17 0 28.5 11.5T840-600q0 17-11.5 28.5T800-560ZM400-80v-144L296-120l-56-56 160-160v-64h-64L176-240l-56-56 104-104H80v-80h144L120-584l56-56 160 160h64v-64L240-704l56-56 104 104v-144h80v144l104-104 56 56-160 160v64h320v80H656l104 104-56 56-160-160h-64v64l160 160-56 56-104-104v144h-80Zm360-600v-200h80v200h-80Z"))
val Icon.Companion.speedometer get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M418-340q24 24 62 23.5t56-27.5l224-336-336 224q-27 18-28.5 55t22.5 61Zm62-460q59 0 113.5 16.5T696-734l-76 48q-33-17-68.5-25.5T480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-36-8.5-70T766-540l48-76q30 47 47.5 100T880-406q1 57-13 109t-41 99q-11 18-30 28t-40 10H204q-21 0-40-10t-30-28q-26-45-40-95.5T80-400q0-83 31.5-155.5t86-127Q252-737 325-768.5T480-800Zm7 313Z"))
val Icon.Companion.electric get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m422-232 207-248H469l29-227-185 267h139l-30 208ZM320-80l40-280H160l360-520h80l-40 320h240L400-80h-80Zm151-390Z"))
val Icon.Companion.accuracy get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M468-240q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 162l-84-25q-13-54-56-88.5T480-640q-66 0-113 47t-47 113q0 57 34.5 100t88.5 56l25 84Zm48 158q-9 2-18 2h-18q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v18q0 9-2 18l-78-24v-12q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h12l24 78Zm305 22L650-231 600-80 480-480l400 120-151 50 171 171-79 79Z"))
val Icon.Companion.sell get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M570-104q-23 23-57 23t-57-23L104-456q-11-11-17.5-26T80-514v-286q0-33 23.5-56.5T160-880h286q17 0 32 6.5t26 17.5l352 353q23 23 23 56.5T856-390L570-104Zm-57-56 286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640ZM160-800Z"))
val Icon.Companion.buy get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"))
val Icon.Companion.manual get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M160-120q-50 0-85-35t-35-85q0-39 22.5-70t57.5-43v-254q-35-12-57.5-43T40-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T200-607v87h240v-87q-35-12-57.5-43T360-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T520-607v87h200q17 0 28.5-11.5T760-560v-47q-35-12-57.5-43T680-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T840-607v47q0 50-35 85t-85 35H520v87q35 12 57.5 43t22.5 70q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-39 22.5-70t57.5-43v-87H200v87q35 12 57.5 43t22.5 70q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T200-240q0-17-11.5-28.5T160-280q-17 0-28.5 11.5T120-240q0 17 11.5 28.5T160-200Zm0-480q17 0 28.5-11.5T200-720q0-17-11.5-28.5T160-760q-17 0-28.5 11.5T120-720q0 17 11.5 28.5T160-680Zm320 480q17 0 28.5-11.5T520-240q0-17-11.5-28.5T480-280q-17 0-28.5 11.5T440-240q0 17 11.5 28.5T480-200Zm0-480q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm320 0q17 0 28.5-11.5T840-720q0-17-11.5-28.5T800-760q-17 0-28.5 11.5T760-720q0 17 11.5 28.5T800-680ZM160-240Zm0-480Zm320 480Zm0-480Zm320 0Z"))
val Icon.Companion.missingTitle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Zm0 320q11 0 18.5-7.5T706-186q0-11-7.5-18.5T680-212q-11 0-18.5 7.5T654-186q0 11 7.5 18.5T680-160Zm-18-76h36v-10q0-11 6-19.5t14-16.5q14-12 22-23t8-31q0-29-19-46.5T680-400q-23 0-41.5 13.5T612-350l32 14q3-12 12.5-21t23.5-9q15 0 23.5 7.5T712-336q0 11-6 18.5T692-302q-6 6-12.5 12T668-276q-3 6-4.5 12t-1.5 14v14Z"))
val Icon.Companion.recycling get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m368-592 89-147-59-98q-12-20-34.5-20T329-837l-98 163 137 82Zm387 272-89-148 139-80 64 107q11 17 12 38t-9 39q-10 20-29.5 32T800-320h-45ZM640-40 480-200l160-160v80h190l-58 116q-11 20-30 32t-42 12h-60v80Zm-387-80q-20 0-36.5-10.5T192-158q-8-16-7.5-33.5T194-224l34-56h172v160H253Zm-99-114L89-364q-9-18-8.5-38.5T92-441l16-27-68-41 219-55 55 220-69-42-91 152Zm540-342-219-55 69-41-125-208h141q21 0 39.5 10.5T629-841l52 87 68-42-55 220Z"))
val Icon.Companion.lemon get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-120q-117 0-198.5-81.5T200-400q0-94 55.5-168.5T401-669q-20-5-39-14.5T328-708q-33-33-42.5-78.5T281-879q47-5 92.5 4.5T452-832q23 23 33.5 52t13.5 61q13-31 31.5-58.5T572-828q11-11 28-11t28 11q11 11 11 28t-11 28q-22 22-39 48.5T564-667q88 28 142 101.5T760-400q0 117-81.5 198.5T480-120Zm0-80q83 0 141.5-58.5T680-400q0-83-58.5-141.5T480-600q-83 0-141.5 58.5T280-400q0 83 58.5 141.5T480-200Zm0-200Z"))
val Icon.Companion.canada get() = Icon(1.5.rem, 1.5.rem, 0, 0, 55, 39, listOf(
    "M27.6,3.6l-2.62,4.89c-0.3,0.53-0.83,0.48-1.37,0.18l-1.9-0.98l1.41,7.5c0.3,1.37-0.66,1.37-1.13,0.78 l-3.31-3.7l-0.53,1.88c-0.06,0.25-0.33,0.51-0.74,0.45l-4.18-0.88l1.1,4c0.24,0.89,0.42,1.26-0.24,1.49l-1.49,0.7l7.21,5.85 c0.29,0.22,0.43,0.62,0.32,0.98l-0.63,2.07c2.48-0.29,4.71-0.72,7.19-0.98c0.22-0.02,0.58,0.33,0.58,0.59L26.95,36h1.2l-0.19-7.56 c0-0.26,0.33-0.63,0.55-0.61c2.48,0.27,4.71,0.7,7.19,0.98l-0.64-2.08c-0.11-0.36,0.04-0.75,0.32-0.98l7.21-5.85l-1.49-0.7 c-0.66-0.24-0.48-0.6-0.24-1.49l1.1-4l-4.18,0.88c-0.41,0.06-0.68-0.2-0.74-0.45l-0.53-1.88l-3.31,3.7 c-0.47,0.59-1.42,0.59-1.13-0.78l1.41-7.5l-1.9,0.98c-0.53,0.3-1.07,0.34-1.37-0.18L27.6,3.6L27.6,3.6L27.6,3.6z M27.6,3.6 L27.6,3.6L27.6,3.6L27.6,3.6L27.6,3.6z",
))
val Icon.Companion.certification get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf(
  "m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"
))
val Icon.Companion.lightMode get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"))
val Icon.Companion.darkMode get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"))
val Icon.Companion.checkCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.emptyCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.xCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.creditCard get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"))
val Icon.Companion.checkbook get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M240-360h280l80-80H240v80Zm0-160h240v-80H240v80Zm-80-160v400h280l-80 80H80v-560h800v120h-80v-40H160Zm756 212q5 5 5 11t-5 11l-36 36-70-70 36-36q5-5 11-5t11 5l48 48ZM520-120v-70l266-266 70 70-266 266h-70ZM160-680v400-400Z"))
val Icon.Companion.chevronDown get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"))

val Icon.Companion.logo get() = Icon(5.rem, 1.rem, 0, 0, 507, 108, listOf(
    "M220.77,17.08H184l-18.64,41H149.68l18.64-41H124.14v1.7l5.37,2.87L115,53.64c-2.25,5,0,9,4.9,9H191c4.95,0,10.89-4,13.14-9l16.63-36.57Z",
    "M269.43,45.7H225.66c-5.64,0-6.34-5-4.42-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.07,4.57H264.74L259,34.34h43.78c5.64,0,6.34,5,4.42,9.18L303,52.6c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37Z",
    "M363.12,45.7H319.34c-5.64,0-6.33-5-4.41-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.08,4.57H358.43l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56H357.5l5.62-12.37Z",
    "M431.79,17.08h67.88c6.14,0,8.93,5,6.15,11.14L495.26,51.43c-2.78,6.12-10.05,11.2-16.18,11.2H411.3c-6.13,0-9.06-5.11-6.27-11.24l10.54-23.17c2.78-6.13,10.08-11.14,16.22-11.14Zm23.5,4.57h16.79L455.51,58.07H438.72l16.57-36.42Z",
    "M39.01 74.66L2.27 74.66L26.38 21.65L 21.01 18.78L21.01 17.08L65.19 17.08L39.01 74.66L39.01 74.66Z",
    "M54.47 74.66L78.58 21.65L73.21 18.78L 73.21 17.08L117.39 17.08L93.28 70.09L486.77 70.09L484.7 74.66L54.47 74.66L54.47 74.66Z",
    "M67.65 11.67L30.92 11.67L36.23 0L 72.96 0L67.65 11.67L67.65 11.67Z",
))
val Icon.Companion.schedule get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf(
    "m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"
))

fun Resources.iconLogo(theme: Theme) = ImageVector(
    4.rem, 4.rem, 0, 0, 72, 72,
    paths = listOf(
        ImageVector.Path(fillColor = Color.fromHex(0x04A7CD), path = "M 67.13,13.33 37.1,13.55 c -9.06,0.47 -19.6,9.65 -22.16,19.7 v 0 c -0.13,0.52 0.18,0.94 0.7,0.94 h 19.33 c 0.2,-1.38 1.35,-2.42 2.81,-2.42 h 25.59 l 4.46,-17.5 c 0.13,-0.52 -0.18,-0.94 -0.7,-0.94 z",),
        ImageVector.Path(fillColor = Color.fromHex(0x008EBC), path = "M 56.37,37.81 h -19.3 c -0.06,1.52 -1.27,2.72 -2.82,2.72 H 8.56 l -4.39,17.2 c -0.13,0.52 0.18,0.94 0.7,0.94 L 34.9,58.45 C 44.94,58.1 54.5,48.8 57.06,38.75 57.2,38.23 56.88,37.81 56.37,37.81 Z",),
    )
)
fun Resources.fullLogo(theme: Theme) = ImageVector(
    22.6.rem, 7.1.rem, 0, 0, 226, 71,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M 76.59852,65.885 L 78.07852,63.855 H 80.05852 C 80.52852,63.855 81.08852,63.855 81.45852,63.875 C 81.24852,63.595 80.91852,63.105 80.65852,62.735 L 79.24852,60.645 L 75.52852,65.875 H 72.63852 L 77.71852,58.745 C 78.04852,58.285 78.53852,57.865 79.29852,57.865 C 80.02852,57.865 80.50852,58.255 80.84852,58.745 L 85.74852,65.875 H 76.59852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 95.97852,62.435 C 95.96852,64.815 94.32852,66.005 91.04852,66.005 C 87.24852,66.005 85.61852,64.815 85.62852,62.435 L 85.64852,57.995 H 88.07852 L 88.05852,62.435 C 88.05852,63.605 88.54852,63.975 91.05852,63.975 C 92.92852,63.975 93.52852,63.535 93.52852,62.435 L 93.54852,57.995 H 95.99852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 107.38852,63.855 L 105.81852,65.885 H 101.85852 C 99.12852,65.885 97.11852,64.155 97.11852,61.865 C 97.12852,59.555 99.14852,57.995 101.87852,57.995 H 107.40852 L 105.83852,60.045 H 101.87852 C 100.55852,60.045 99.56852,60.835 99.55852,61.965 C 99.55852,63.085 100.52852,63.855 101.85852,63.855 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 118.05852,57.995 L 116.46852,60.045 H 113.64852 L 113.62852,65.875 H 111.18852 L 111.20852,60.045 H 107.50852 L 109.09852,57.995 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 121.24852,65.885 H 118.80852 L 118.83852,57.995 H 121.27852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 134.06852,61.935 C 134.05852,64.755 132.11852,66.015 128.28852,66.015 C 124.44852,66.015 122.52852,64.755 122.53852,61.935 C 122.54852,59.115 124.47852,57.865 128.31852,57.865 C 132.14852,57.865 134.07852,59.115 134.06852,61.935 Z M 131.63852,61.935 C 131.64852,60.235 130.75852,59.915 128.30852,59.915 C 125.85852,59.915 124.96852,60.235 124.96852,61.935 C 124.95852,63.635 125.84852,63.965 128.29852,63.965 C 130.74852,63.955 131.63852,63.625 131.63852,61.935 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 142.31852,65.585 L 137.49852,61.245 L 137.47852,65.875 H 135.25852 L 135.27852,59.335 C 135.27852,58.285 135.91852,57.865 136.61852,57.865 C 136.90852,57.865 137.20852,57.935 137.59852,58.285 L 142.41852,62.625 L 142.43852,57.995 H 144.66852 L 144.64852,64.525 C 144.64852,65.575 144.00852,66.005 143.29852,66.005 C 142.98852,66.015 142.68852,65.935 142.31852,65.585 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 153.40852,61.145 C 155.32852,61.145 156.36852,61.865 156.35852,63.305 C 156.34852,64.925 155.36852,65.885 152.96852,65.885 H 145.62852 L 147.19852,63.855 H 153.15852 C 153.73852,63.855 153.99852,63.685 153.99852,63.275 C 153.99852,62.875 153.73852,62.705 153.16852,62.705 H 148.87852 C 146.84852,62.705 145.91852,61.795 145.92852,60.445 C 145.93852,58.965 146.92852,57.995 149.31852,57.995 H 156.16852 L 154.58852,60.045 H 149.11852 C 148.54852,60.045 148.27852,60.185 148.27852,60.585 C 148.27852,60.985 148.54852,61.135 149.10852,61.135 H 153.40852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 158.51852,63.725 C 159.25852,63.725 159.84852,64.145 159.84852,64.875 C 159.84852,65.595 159.25852,66.005 158.50852,66.005 C 157.77852,66.005 157.18852,65.595 157.18852,64.875 C 157.19852,64.145 157.78852,63.725 158.51852,63.725 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 170.85852,63.855 L 169.28852,65.885 H 165.32852 C 162.59852,65.885 160.58852,64.155 160.58852,61.865 C 160.59852,59.555 162.61852,57.995 165.34852,57.995 H 170.87852 L 169.30852,60.045 H 165.34852 C 164.02852,60.045 163.03852,60.835 163.02852,61.965 C 163.02852,63.085 163.99852,63.855 165.32852,63.855 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 183.00852,61.935 C 182.99852,64.755 181.05852,66.015 177.22852,66.015 C 173.38852,66.015 171.46852,64.755 171.47852,61.935 C 171.48852,59.115 173.41852,57.865 177.25852,57.865 C 181.08852,57.865 183.01852,59.115 183.00852,61.935 Z M 180.57852,61.935 C 180.58852,60.235 179.69852,59.915 177.24852,59.915 C 174.79852,59.915 173.90852,60.235 173.90852,61.935 C 173.89852,63.635 174.78852,63.965 177.23852,63.965 C 179.68852,63.955 180.56852,63.625 180.57852,61.935 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 189.35852,60.935 C 190.04852,62.145 190.35852,62.735 190.61852,63.295 C 190.87852,62.745 191.18852,62.165 191.88852,60.945 L 193.15852,58.705 C 193.44852,58.195 193.86852,57.865 194.50852,57.865 C 195.11852,57.865 195.65852,58.215 195.86852,58.945 L 197.83852,65.875 H 195.40852 L 194.67852,63.125 C 194.43852,62.225 194.26852,61.595 194.14852,60.955 C 193.88852,61.505 193.58852,62.105 193.04852,63.135 L 191.97852,65.115 C 191.56852,65.895 191.15852,65.995 190.56852,65.995 C 189.97852,65.995 189.57852,65.885 189.16852,65.115 L 188.11852,63.125 C 187.54852,62.045 187.26852,61.485 187.01852,60.955 C 186.89852,61.575 186.71852,62.235 186.47852,63.125 L 185.72852,65.875 H 183.33852 L 185.40852,58.945 C 185.61852,58.215 186.17852,57.865 186.78852,57.865 C 187.40852,57.865 187.83852,58.185 188.11852,58.705 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 158.38852,47.865 H 153.14852 L 163.04852,28.945 H 168.28852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 188.34852,47.865 H 183.10852 L 193.00852,28.945 H 198.24852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 151.91852,42.995 H 142.51852 C 139.65852,42.995 138.51852,41.155 139.91852,38.475 C 141.33852,35.765 144.45852,33.865 147.29852,33.865 H 156.69852 L 159.26852,28.945 H 149.86852 C 143.98852,28.945 137.70852,32.675 134.80852,38.215 C 131.93852,43.705 134.09852,47.855 139.96852,47.855 H 149.36852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 213.44852,42.995 H 204.04852 C 201.18852,42.995 200.04852,41.155 201.44852,38.475 C 202.86852,35.765 205.98852,33.865 208.82852,33.865 H 218.22852 L 220.79852,28.945 H 211.39852 C 205.51852,28.945 199.23852,32.675 196.33852,38.215 C 193.46852,43.705 195.62852,47.855 201.49852,47.855 H 210.89852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 136.01852,28.945 C 130.25185,28.945 124.48519,28.945 118.71852,28.945 C 115.41852,35.25167 112.11852,41.55833 108.81852,47.865 C 114.58852,47.865 120.35852,47.865 126.12852,47.865 C 126.97519,46.24167 127.82185,44.61833 128.66852,42.995 C 124.65519,42.995 120.64185,42.995 116.62852,42.995 C 117.07852,42.13833 117.52852,41.28167 117.97852,40.425 C 121.99185,40.425 126.00519,40.425 130.01852,40.425 C 130.76852,38.99167 131.51852,37.55833 132.26852,36.125 C 128.25519,36.125 124.24185,36.125 120.22852,36.125 C 120.62185,35.37167 121.01519,34.61833 121.40852,33.865 C 125.42185,33.865 129.43519,33.865 133.44852,33.865 C 134.30519,32.225 135.16185,30.585 136.01852,28.945 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 185.41852,36.325 H 173.55852 L 174.84852,33.865 H 186.70852 L 189.27852,28.945 H 172.15852 L 162.25852,47.865 H 167.52852 L 171.14852,40.935 H 183.00852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 107.26852,28.945 H 93.18852 L 90.61852,33.865 H 105.17852 C 106.79852,33.865 107.30852,34.685 106.60852,36.015 C 105.92852,37.315 104.52852,38.195 102.90852,38.195 H 88.31852 L 83.25852,47.865 H 88.47852 L 91.00852,43.025 H 99.87852 C 105.14852,43.025 109.67852,40.225 111.86852,36.045 C 114.05852,31.825 112.50852,28.945 107.26852,28.945 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 73.20852,33.865 H 87.04852 L 89.61852,28.945 H 76.18852 C 71.04852,28.945 67.70852,31.265 65.83852,34.825 C 64.13852,38.075 64.99852,40.255 69.35852,40.255 H 78.57852 C 79.81852,40.255 80.16852,40.655 79.66852,41.615 C 79.14852,42.605 78.38852,43.005 77.14852,43.005 H 63.73852 L 61.01852,47.865 H 74.19852 C 79.36852,47.865 82.67852,45.545 84.70852,41.675 C 86.51852,38.225 85.18852,36.495 81.05852,36.495 H 71.83852 C 70.61852,36.495 70.22852,36.125 70.73852,35.165 C 71.23852,34.205 71.99852,33.865 73.20852,33.865 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 136.50852,24.175 L 139.68852,19.325 H 143.93852 C 144.94852,19.325 146.13852,19.325 146.94852,19.385 C 146.49852,18.705 145.79852,17.555 145.23852,16.645 L 142.22852,11.655 L 134.23852,24.185 H 128.04852 L 138.96852,7.115 C 139.67852,6.015 140.71852,4.995 142.36852,4.995 C 143.93852,4.995 144.96852,5.925 145.69852,7.115 L 156.19852,24.185 H 136.50852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 173.08852,23.465 L 162.76852,13.085 L 162.71852,24.175 H 157.96852 L 158.03852,8.515 C 158.04852,6.005 159.41852,4.985 160.90852,4.985 C 161.53852,4.985 162.16852,5.155 162.99852,6.005 L 173.31852,16.385 L 173.36852,5.295 H 178.14852 L 178.07852,20.925 C 178.06852,23.435 176.69852,24.485 175.17852,24.485 C 174.51852,24.485 173.88852,24.315 173.08852,23.465 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 98.25852,14.465 C 99.41852,13.985 100.56852,12.745 100.57852,10.515 C 100.58852,7.045 97.99852,5.295 93.94852,5.295 H 82.95852 L 80.38852,10.205 H 94.14852 C 94.87852,10.205 95.61852,10.485 95.60852,11.415 C 95.60852,12.375 94.89852,12.685 94.13852,12.685 H 79.16852 L 79.11852,24.165 H 93.97852 C 99.30852,24.165 100.86852,21.795 100.87852,18.665 C 100.89852,16.415 99.71852,14.945 98.25852,14.465 Z M 94.03852,19.325 H 84.40852 L 84.41852,16.725 H 94.04852 C 95.18852,16.725 95.66852,17.175 95.65852,17.995 C 95.65852,18.865 95.17852,19.325 94.03852,19.325 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 124.38852,20.505 C 123.22852,19.175 122.59852,18.505 122.01852,18.025 C 124.79852,17.405 126.45852,14.975 126.46852,12.045 C 126.48852,6.655 121.64852,5.305 117.79852,5.305 H 106.11852 L 103.54852,10.215 H 118.20852 C 120.00852,10.215 121.08852,10.945 121.07852,12.415 C 121.06852,13.715 119.80852,14.535 118.18852,14.535 H 103.47852 L 103.43852,24.185 H 108.66852 L 108.68852,19.195 H 116.72852 L 120.47852,24.185 H 127.47852 Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 192.54852,5.295 H 183.77852 L 181.20852,10.205 H 192.52852 C 195.35852,10.205 197.52852,12.155 197.51852,14.805 C 197.50852,17.515 195.39852,19.315 192.48852,19.315 H 186.41852 L 186.44852,12.405 H 181.18852 L 181.13852,24.175 H 192.46852 C 198.33852,24.175 202.67852,20.025 202.69852,14.555 C 202.71852,9.105 198.45852,5.295 192.54852,5.295 Z"),
        ImageVector.Path(fillColor = Color.fromHex(0x04A7CD), path = "M 64.66852,5.155 L 36.31852,5.365 C 27.76852,5.815 17.81852,14.475 15.39852,23.975 V 23.975 C 15.27852,24.465 15.56852,24.865 16.05852,24.865 H 34.30852 C 34.48852,23.565 35.58852,22.575 36.95852,22.575 H 61.11852 L 65.32852,6.055 C 65.44852,5.555 65.15852,5.155 64.66852,5.155 Z"),
        ImageVector.Path(fillColor = Color.fromHex(0x008EBC), path = "M 54.49852,28.275 H 36.27852 C 36.21852,29.715 35.07852,30.845 33.60852,30.845 H 9.36852 L 5.22852,47.085 C 5.10852,47.575 5.39852,47.975 5.88852,47.975 L 34.23852,47.765 C 43.71852,47.435 52.73852,38.655 55.15852,29.155 C 55.28852,28.665 54.98852,28.275 54.49852,28.275 Z"),


    )
)
fun Resources.lussoLogo(theme: Theme) = ImageVector(
    10.rem, 1.5.rem, 0, 0, 452, 96,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M11.55,95.45v-22.77H3.36v-5.25h22.35v5.25h-8.15v22.77h-6.01Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M39.19,95.45v-28.02h19.83v5.25h-13.86v5.92h13.57v5.25h-13.57v6.34h13.86v5.25h-19.83Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M71.99,81.46c0-8.61,6.51-14.49,14.91-14.49,6.17,0,9.75,3.36,11.72,6.85l-5.12,2.52c-1.18-2.27-3.7-4.07-6.6-4.07-5.08,0-8.78,3.91-8.78,9.2s3.7,9.2,8.78,9.2c2.9,0,5.42-1.76,6.6-4.07l5.12,2.48c-2.02,3.49-5.54,6.89-11.72,6.89-8.4,0-14.91-5.88-14.91-14.49Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M131.05,95.45v-11.76h-13.23v11.76h-5.96v-28.02h5.96v11.01h13.23v-11.01h6.01v28.02h-6.01Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M171.53,95.45l-13.36-18.27v18.27h-5.96v-28.02h6.13l12.98,17.6v-17.6h5.96v28.02h-5.75Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M191.14,81.46c0-8.44,6.17-14.49,14.62-14.49s14.66,6.05,14.66,14.49-6.17,14.49-14.66,14.49-14.62-6.05-14.62-14.49ZM214.29,81.46c0-5.25-3.36-9.2-8.53-9.2s-8.49,3.95-8.49,9.2,3.32,9.2,8.49,9.2,8.53-3.99,8.53-9.2Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M234.28,95.45v-28.02h5.96v22.77h11.85v5.25h-17.81Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M263.43,81.46c0-8.44,6.17-14.49,14.62-14.49s14.66,6.05,14.66,14.49-6.17,14.49-14.66,14.49-14.62-6.05-14.62-14.49ZM286.57,81.46c0-5.25-3.36-9.2-8.53-9.2s-8.49,3.95-8.49,9.2,3.32,9.2,8.49,9.2,8.53-3.99,8.53-9.2Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M305.22,81.46c0-8.86,6.72-14.49,14.91-14.49,5.84,0,9.49,2.94,11.59,6.26l-4.96,2.69c-1.26-1.97-3.66-3.65-6.64-3.65-5.08,0-8.78,3.91-8.78,9.2s3.7,9.2,8.78,9.2c2.48,0,4.83-1.09,5.96-2.14v-3.36h-7.39v-5.21h13.36v10.75c-2.86,3.19-6.85,5.29-11.93,5.29-8.19,0-14.91-5.71-14.91-14.53Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M346.46,95.45v-28.02h5.96v28.02h-5.96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M367.63,95.45v-28.02h19.83v5.25h-13.86v5.92h13.57v5.25h-13.57v6.34h13.86v5.25h-19.83Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M399.88,91.5l3.24-4.54c1.97,2.06,5.04,3.78,8.91,3.78,3.28,0,4.87-1.51,4.87-3.11,0-4.96-16.13-1.55-16.13-12.1,0-4.66,4.03-8.53,10.63-8.53,4.45,0,8.15,1.34,10.92,3.91l-3.32,4.37c-2.27-2.1-5.29-3.07-8.15-3.07-2.56,0-3.99,1.13-3.99,2.81,0,4.45,16.09,1.47,16.09,11.93,0,5.12-3.65,8.99-11.22,8.99-5.38,0-9.24-1.81-11.85-4.45Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M166.3.01h-36.77l-18.64,41h-15.68L113.85.01h-44.18v1.7l5.37,2.87-14.51,31.99c-2.25,5,0,9,4.9,9h71.1c4.95,0,10.89-4,13.14-9L166.3,0h0Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M214.96,28.63h-43.77c-5.64,0-6.34-5-4.42-9.17l4.28-9.41C174.75,1.91,181.62.05,189.91.05h74.8l-2.07,4.57h-52.37l-5.74,12.65h43.78c5.64,0,6.34,5,4.42,9.18l-4.2,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37.03.03Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M308.65,28.63h-43.78c-5.64,0-6.33-5-4.41-9.17l4.28-9.41C268.44,1.91,275.31.05,283.6.05h74.8l-2.08,4.57h-52.36l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56h52.37l5.62-12.37h0Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 377.32031 0.009765625 C 371.18032 -0.000234365 363.87961 5.0203968 361.09961 11.150391 L 350.56055 34.320312 C 347.77055 40.450306 350.70008 45.560547 356.83008 45.560547 L 424.60938 45.560547 C 430.73937 45.560547 438.00907 40.479369 440.78906 34.359375 L 451.34961 11.150391 C 454.12961 5.0103968 451.33921 0.009765625 445.19922 0.009765625 L 377.32031 0.009765625 z M 400.82031 4.5800781 L 417.60938 4.5800781 L 401.03906 41 L 384.25 41 L 400.82031 4.5800781 z "),
        ImageVector.Path(fillColor = Color.red, path = "M 0 57.59 L 24.11 4.58 L 18.74 1.71 L 18.74 .01 L 62.92 .01 L 38.81 53.02 L 432.3 53.02 L 430.23 57.59 L 0 57.59 L 0 57.59 Z"),
    )
)