// Automatically generated based off UploadEarlyEndpointModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.files

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.uuid
import com.lightningkite.now
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.minutes
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareUploadForNextRequestFields() {
    val props: Array<SerializableProperty<UploadForNextRequest, *>> = arrayOf(UploadForNextRequest__id, UploadForNextRequest_file, UploadForNextRequest_expires)
    UploadForNextRequest.serializer().properties { props }
}
val <K> DataClassPath<K, UploadForNextRequest>._id: DataClassPath<K, UUID> get() = this[UploadForNextRequest__id]
val <K> DataClassPath<K, UploadForNextRequest>.file: DataClassPath<K, ServerFile> get() = this[UploadForNextRequest_file]
val <K> DataClassPath<K, UploadForNextRequest>.expires: DataClassPath<K, Instant> get() = this[UploadForNextRequest_expires]
inline val UploadForNextRequest.Companion.path: DataClassPath<UploadForNextRequest, UploadForNextRequest> get() = path<UploadForNextRequest>()


object UploadForNextRequest__id: SerializableProperty<UploadForNextRequest, UUID> {
    override val name: String = "_id"
    override fun get(receiver: UploadForNextRequest): UUID = receiver._id
    override fun setCopy(receiver: UploadForNextRequest, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = UploadForNextRequest.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object UploadForNextRequest_file: SerializableProperty<UploadForNextRequest, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: UploadForNextRequest): ServerFile = receiver.file
    override fun setCopy(receiver: UploadForNextRequest, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = UploadForNextRequest.serializer().tryFindAnnotations("file")
}
object UploadForNextRequest_expires: SerializableProperty<UploadForNextRequest, Instant> {
    override val name: String = "expires"
    override fun get(receiver: UploadForNextRequest): Instant = receiver.expires
    override fun setCopy(receiver: UploadForNextRequest, value: Instant) = receiver.copy(expires = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = UploadForNextRequest.serializer().tryFindAnnotations("expires")
    override val default: Instant = now().plus(15.minutes)
}
