package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.ilussobsa.sdk.sessionToken
import com.ilussobsa.utils.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.l2.lazyExpanding
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import kotlin.js.JsName
import kotlin.jvm.JvmName
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable


@Routable("/dealership-requests")
class DealershipRequestReviewScreen : KiteUiScreen {

    override val title: Readable<String> = Constant("Dealership Request Review")

    @Serializable
    enum class JoinRequestFilterTab(
        val text: String,
        val filter: ReactiveContext.() -> Condition<DealershipJoinRequest>
    ) {
        Pending(text = Strings.pending, filter = {
            condition {
                it.accepted.eq(null) and it.archived.eq(null)
            }
        }),
        Accepted(text = Strings.accepted, filter = {
            condition {
                it.accepted.neq(null)
            }
        }),
        Rejected(text = Strings.rejected, filter = {
            condition {
                it.accepted.eq(null) and it.archived.neq(null)
            }
        }),
    }

    @QueryParameter("tab")
    val tab = Property(JoinRequestFilterTab.Pending)

    val query = shared {
        Query(
            condition<DealershipJoinRequest> {
                tab().filter()
            },
            orderBy = sort { it.createdAt.descending() },
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        pageWithMaxWidth(60.rem) {
            col {
                link {
                    row {
                        centered - icon(Icon.add, "")
                        centered - text("Add new request")
                    }
                    to = { DealershipRequestReviewDetailScreen(nullUuid) }
                }
                scrollsHorizontally - compact - card - row {
                    gravity(Align.Start, Align.Center) - row {
                        for (tab in JoinRequestFilterTab.entries) {
                            radioToggleButton {
                                checked bind this@DealershipRequestReviewScreen.tab.equalTo(tab)
                                row {
                                    col {
                                        text(tab.text)
                                    }
                                }
                            }
                        }
                    }
                }
                expanding - recyclerView {
                    children(shared {
                        currentSession().dealershipJoinRequests.query(query())()
                    }) {
                        card - link {
                            row {
                                expanding - col {
                                    h3 { ::content { it().name } }
                                    text { ::content { it().address.toString() } }
                                }
                                col {
                                    text { ::content { it().billingContact.name } }
                                    text { ::content { it().billingContact.phone } }
                                    text { ::content { it().billingContact.email } }
                                }
                            }
                            ::to {
                                val id = it()._id
                                { DealershipRequestReviewDetailScreen(id) }
                            }
                        }
                    }
                }
            }
        }
    }
}


@Routable("/dealership-requests/{id}")
class DealershipRequestReviewDetailScreen(val id: UUID = nullUuid) : KiteUiScreen, ControlsNavVisibility {
    override fun ReactiveContext.showNav(): Boolean = sessionToken() != null

    private val notReadOnly = shared { existing()?.accepted == null && existing()?.archived == null }

    private inner class Field<T>(
        name: String,
        val path: DataClassPath<DealershipJoinRequest, T>,
        val default: T,
        val required: Boolean = false,
        val repair: (T)->T = { it }
    ) {
        val nameLabel: String = "$name${if (required) " *" else ""}"

        val draft = Property(default)
        val existing = Property(default)
        val enabled = notReadOnly
        fun assign(r: DealershipJoinRequest) {
            draft.value = path.get(r) ?: default
            existing.value = path.get(r) ?: default
        }

        fun set(r: DealershipJoinRequest): DealershipJoinRequest {
            return path.set(r, repair(draft.value))
        }

        fun update(builder: ModificationBuilder<DealershipJoinRequest>) {
            with(builder) {
                path.assign(repair(draft.value))
            }
        }

        fun initDefault() {
            existing.value = repair(default)
        }
        fun modified() = draft.value != repair(existing.state.onSuccess { it } ?: default)
    }

    private val fields = ArrayList<Field<*>>()
    private val name = Field(Strings.dealershipName, DealershipJoinRequest.path.name, "", required = true) { it.trim() }.also { fields.add(it) }
    private val address_street = Field(Strings.streetAddress, DealershipJoinRequest.path.address.street, "", required = true) { it.trim() }.also { fields.add(it) }
    private val address_city = Field(Strings.city, DealershipJoinRequest.path.address.city, "", required = true) { it.trim() }.also { fields.add(it) }
    private val address_zip = Field(Strings.zip, DealershipJoinRequest.path.address.zip,"", required = true) { it.trim() }.also { fields.add(it) }
    private val address_state = Field(Strings.state, DealershipJoinRequest.path.address.state, UsState.CA, required = true).also { fields.add(it) }
    private val url = Field("URL", DealershipJoinRequest.path.url, "") { it?.trim() }.also { fields.add(it) }
    private val billingContact_name = Field(Strings.name2, DealershipJoinRequest.path.billingContact.name, "", required = true) { it.trim() }.also { fields.add(it) }
    private val billingContact_email = Field(Strings.email2, DealershipJoinRequest.path.billingContact.email, "", required = true) { it.trim() }.also { fields.add(it) }
    private val billingContact_phone = Field(Strings.phoneNumber, DealershipJoinRequest.path.billingContact.phone, "", required = true) { it.trim() }.also { fields.add(it) }
    private val usedCarManager_name = Field(Strings.name2, DealershipJoinRequest.path.usedCarManager.name, "", required = true) { it.trim() }.also { fields.add(it) }
    private val usedCarManager_email = Field(Strings.email2, DealershipJoinRequest.path.usedCarManager.email, "", required = true) { it.trim() }.also { fields.add(it) }
    private val usedCarManager_phone = Field(Strings.phoneNumber, DealershipJoinRequest.path.usedCarManager.phone, "", required = true) { it.trim() }.also { fields.add(it) }
    private val transportManager_name = Field(Strings.name2, DealershipJoinRequest.path.transportManager.name, "") { it.trim() }.also { fields.add(it) }
    private val transportManager_email = Field(Strings.email2, DealershipJoinRequest.path.transportManager.email, "") { it.trim() }.also { fields.add(it) }
    private val transportManager_phone = Field(Strings.phoneNumber, DealershipJoinRequest.path.transportManager.phone, "") { it.trim() }.also { fields.add(it) }
    private val titleManager_name = Field(Strings.name2, DealershipJoinRequest.path.titleManager.name, "") { it.trim() }.also { fields.add(it) }
    private val titleManager_email = Field(Strings.email2, DealershipJoinRequest.path.titleManager.email, "") { it.trim() }.also { fields.add(it) }
    private val titleManager_phone = Field(Strings.phoneNumber, DealershipJoinRequest.path.titleManager.phone, "") { it.trim() }.also { fields.add(it) }
    private val stripeBilling = Field("Use credit card for billing", DealershipJoinRequest.path.stripeBilling, false).also { fields.add(it) }
    private val makes = Field(Strings.makes, DealershipJoinRequest.path.makes, setOf()).also { fields.add(it) }
    private val notes = Field(Strings.notes, DealershipJoinRequest.path.notes, "").also { fields.add(it) }

    private val requiredFieldsFilled = shared {
        fields.filter { it.required }.all {
            val fieldValue = it.draft()
            if (fieldValue is String) {
                fieldValue.isNotBlank()
            } else {
                true
            }
        }
    }

    @JvmName("renderString")
    @JsName("renderString")
    private fun ViewWriter.render(field: Field<String>) {
        compact - col {
            subtext(field.nameLabel)
            fieldTheme - textField {
                hint = field.default
                ::hint { field.existing() }
                content bind field.draft
                ::enabled { field.enabled() }
            }
        }
    }

    @JvmName("renderStringBig")
    @JsName("renderStringBig")
    private fun ViewWriter.renderBig(field: Field<String>) {
        compact - col {
            subtext(field.nameLabel)
            fieldTheme - textArea {
                hint = field.default
                ::hint { field.existing() }
                content bind field.draft
                ::enabled { field.enabled() }
            }
        }
    }

    @JvmName("renderStringN")
    @JsName("renderStringN")
    private fun ViewWriter.render(field: Field<String?>) {
        compact - col {
            subtext(field.nameLabel)
            fieldTheme - textField {
                hint = field.default ?: "None"
                ::hint { field.existing() ?: "None" }
                content bind field.draft.nullToBlank()
                ::enabled { field.enabled() }
            }
        }
    }

    @JvmName("renderUsState")
    @JsName("renderUsState")
    private fun ViewWriter.render(field: Field<UsState>) {
        compact - col {
            subtext(field.nameLabel)
            fieldTheme - select {
                bind(field.draft, Constant(UsState.values().toList())) { it.text }
                ::enabled { field.enabled() }
            }
        }
    }

    @JvmName("renderMakes")
    @JsName("renderMakes")
    private fun ViewWriter.render(field: Field<Set<String>>) {
        compact - col {
            exists = false
            ::exists { currentUser.awaitNotNull().role >= UserRole.Manager }
            subtext(field.nameLabel)
            val makesGetter = shared {
                val q = Query<Make>(orderBy = sort { it._id.ascending() })
                currentSessionNullable.invoke()?.makes?.query(q) ?: sharedSuspending { selectedApi().api.make.query(q, null) }
            }
            fieldTheme - multiselect(
                query = { input ->
                    val makes = makesGetter()().map { it._id }
                    input.takeUnless { it.isBlank() }?.let { s ->
                        makes.filter { it.contains(s, true) }
                    } ?: makes
                },
                draw = { it },
                enabled = { field.enabled() },
                items = field.draft,
            )
        }
    }

    @JvmName("renderBoolean")
    @JsName("renderBoolean")
    private fun ViewWriter.render(field: Field<Boolean>) {
        row {
            checkbox {
                checked bind field.draft
                ::enabled { field.enabled() }
            }
            centered - expanding - text(field.nameLabel)
        }
    }

    val existing = shared { currentSessionNullable.invoke()?.dealershipJoinRequests?.get(id)?.invoke() }
    override val title: Readable<String> =
        shared { existing()?.name?.let { "$it Join Request" } ?: "New Dealership Join Request" }

    private suspend fun insertOrUpdateRequest(): UUID? {
        val e = existing()
        if(e == null) {
            val request = selectedApi().api.dealershipJoinRequest.insert(fields.fold(DealershipJoinRequest()) { a, b ->
                b.set(a)
            }, null)
            return request._id
        } else {
            val changedFields = fields.filter { it.modified() }
            // Empty modification calls fail when LS is deployed
            if (changedFields.isNotEmpty()) {
                currentSessionNullable()?.dealershipJoinRequests?.get(e._id)?.modify(modification {
                    changedFields.forEach { it.update(this) }
                })
            }
            return null
        }
    }

    override fun ViewWriter.render() {
        pageWithMaxWidth(40.rem) {
            launch {
                existing()?.let { r ->
                    fields.forEach { it.assign(r) }
                } ?: fields.forEach { it.initDefault() }
            }

            expanding - scrolls - col {
                if(Platform.current != Platform.Web) {
                    atStart - button {
                        icon(Icon.arrowBack, "Back")
                        onClick { screenNavigator.goBack() }
                    }
                }
                space(4.0)
                centered - image {
                    ::source { Resources.fullLogo(defaultAppTheme) }
                    description = Strings.appName
                    this.description = Strings.appName
                }
                centered - text(Strings.joinAdvertisement)
                card - col {
                    h2("Dealership Info")
                    render(name)
                    render(address_street)
                    row {
                        weight(2f) - render(address_city)
                        weight(1f) - render(address_zip)
                        weight(1f) - render(address_state)
                    }
                    render(url)
                    render(makes)
                }
                card - col {
                    h2(Strings.usedCarManager)
                    render(usedCarManager_name)
                    render(usedCarManager_email)
                    render(usedCarManager_phone)
                }
                card - col {
                    h2(Strings.billingContact)
                    render(billingContact_name)
                    render(billingContact_email)
                    render(billingContact_phone)
                }

                fun expandingFormSection(
                    titleRow: ViewWriter.()->Unit,
                    startExpanded: suspend ()->Boolean = { false },
                    body: ViewWriter.()->Unit
                ) = card - col {
                    val expanded = Property(false)
                    launch { expanded.set(startExpanded()) }
                    row {
                        centered - titleRow()
                        expanding - space()
                        centered - toggleButton {
                            checked bind expanded
                            icon { source = Icon.chevronDown }
                        }
                    }
                    lazyExpanding(expanded) {
                        col {
                            body()
                        }
                    }
                }

                expandingFormSection(
                    { col { h2(Strings.transportContact); subtext(Strings.optional) } },
                    { existing.awaitNotNull().transportManager != DealershipJoinRequestUser.EMPTY }
                ) {
                    render(transportManager_name)
                    render(transportManager_email)
                    render(transportManager_phone)
                }
                expandingFormSection(
                    { col { h2(Strings.titleManager); subtext(Strings.optional) } },
                    { existing.awaitNotNull().titleManager != DealershipJoinRequestUser.EMPTY }
                ) {
                    render(titleManager_name)
                    render(titleManager_email)
                    render(titleManager_phone)
                }
                card - col {
                    h2(Strings.selectPaymentMethod)
                    row {
                        radioButton {
                            checked bind stripeBilling.draft.equalTo(false)
                            ::enabled { stripeBilling.enabled() }
                        }
                        centered - icon { source = Icon.checkbook }
                        centered - expanding - text(Strings.check)
                    }
                    row {
                        radioButton {
                            checked bind stripeBilling.draft
                            ::enabled { stripeBilling.enabled() }
                        }
                        centered - icon { source = Icon.creditCard }
                        centered - expanding - text(Strings.creditCard)
                    }
                }
                onlyWhen {
                    currentUser()?.role?.let { it >= UserRole.Manager } == true && existing()?.accepted == null
                } - card - col {
                    h2(Strings.admin)
                    renderBig(notes)
                    row {
                        expanding - danger - button {
                            ::exists { existing()?.archived == null }
                            text(Strings.reject)
                            onClick {
                                confirmDanger(Strings.rejectRequest, Strings.rejectRequestAreYouSure) {
                                    currentSession().dealershipJoinRequests[id].modify(modification {
                                        it.archived assign now()
                                    })
                                    navigator.goBack()
                                }
                            }
                        }
                        expanding - affirmative - button {
                            text {
                                ::content { if (existing()?.archived == null) Strings.saveAndAccept else Strings.accept }
                            }
                            onClick {
                                val id = insertOrUpdateRequest() ?: id
                                currentSession().dealershipJoinRequest.acceptDealership(id)
                                navigator.goBack()
                            }
                        }
                    }
                }
                val agreed = Property(false)
                card - col {
                    h2("Terms and Conditions")
                    text("By registering for Brand Specific Auctions' Dealer-to-Dealer Auto Auction, you agree to the following terms and conditions:")
                    bold - text("1. Subscription Fee:")
                    row {
                        space()
                        text("• A monthly subscription fee of $500 (USD) will be charged for each dealership (\"rooftop\") registered with Brand Specific Auctions.")
                    }
                    row {
                        space()
                        text("• Each additional rooftop that you choose to register will incur an additional monthly fee of $500 (USD).")
                    }
                    bold - text("2. Payment Terms:")
                    row {
                        space()
                        text("• Payments can be made by check or through our payment portal hosted by Stripe.")
                    }
                    row {
                        space()
                        text("• No charges will be processed until the first auction has taken place.")
                    }
                    bold - text("3. Cancellation Policy:")
                    row {
                        space()
                        text("• You may cancel your subscription at any time by providing 30 days' written notice to Brand Specific Auctions.")
                    }
                    row {
                        space()
                        text("• Subscription fees will continue to be charged during the 30-day notice period and will cease thereafter.")
                    }
                    bold - text("4. Billing and Charges:")
                    row {
                        space()
                        text("• Your subscription will be billed monthly on the anniversary of the first auction.")
                    }
                    row {
                        space()
                        text("• Failure to pay the subscription fees may result in the suspension or termination of your access to the auction platform.")
                    }
                    text("By checking the box below, you acknowledge that you have read, understood, and agree to the above terms and conditions.")
                    space()
                    row {
                        checkbox {
                            checked bind agreed
                        }
                        centered - expanding - text("Agree to terms*")
                    }
                }
                important - button {
                    ::exists { existing()?.archived == null && existing()?.accepted == null }
                    text(Strings.submit)
                    ::enabled { requiredFieldsFilled() && agreed() }
                    onClick {
                        val didInsert = insertOrUpdateRequest() != null
                        if (didInsert) {
                            if (sessionToken() == null) {
                                navigator.replace(LogInScreen().apply { notice.set(LogInScreen.Notice.DealershipRequestSubmissionSuccess) })
                            } else {
                                navigator.replace(DealershipRequestReviewCompleteScreen())
                            }
                        }
                    }
                }
                space(4.0)
            }
        }
    }
}



@Routable("/dealership-requests-complete")
class DealershipRequestReviewCompleteScreen : KiteUiScreen {
    override val title: Readable<String> = Constant("Dealership Request Review")

    override fun ViewWriter.render() {
        stack {
            centered - card - col {
                h1("Thanks!")
                text(Strings.associateWillReview)
            }
        }
    }
}