package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.UserSession
import com.ilussobsa.sdk.currentDealershipId
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.modification
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.serialization.SerializableProperty
import kotlin.coroutines.resume
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable


@Routable("upload-vehicle/{vehicleId}/images")

class TakeNewVehicleImagesScreen(val vehicleId: UUID = nullUuid) : KiteUiScreen {

    @QueryParameter
    val stage = Property<PhotoTakeStage?>(PhotoTakeStage.Exterior)

    @QueryParameter
    val single = Property<Boolean>(false)

    private fun advance() {
        val o = stage.value?.ordinal ?: return
        if (single.value) {
            stage.value = null
        } else {
            stage.value = PhotoTakeStage.values().getOrNull(o + 1)
        }
    }

    /**
     * Take an exterior picture
     * Vin plate scan (auto-takes picture when scan succeeds, check button only appears after about 10 seconds)
     * Two more normal pictures
     */

    data class ImageToBe(
        val local: ImageLocal? = null,
        var upload: Async<ServerFile>? = null,
        var alreadyHad: ServerFile? = null,
    ) {
        fun tryUpload(session: UserSession) {
            local?.let { local ->
                upload = asyncGlobal {
                    suppressConnectivityIssues {
                        val request = session.uploadFileForRequest()
                        val upload = request.upload(RequestBodyFile(local.file))
                        upload
                    }
                }
            }
        }

        suspend fun upload(session: UserSession): ServerFile? {
            val local = local ?: return alreadyHad
            return try {
                upload?.await()
            } catch (e: Exception) {
                null
            } ?: run {
                val request = session.uploadFileForRequest()
                val upload = request.upload(RequestBodyFile(local.file))
                upload
            }
        }
    }

    private val vin = Property("")
    val images = HashMap<PhotoTakeStage, ImageToBe>()

    override fun ViewWriter.render() {
        if (Platform.current == Platform.Web) {
            stack {
                centered - col {
                    h1("VIN:")
                    sizeConstraints(width = 30.rem) - fieldTheme - textField {
                        hint = vinFormat.map { it.first() }.joinToString("")
                        content bind vin
                    }
                    important - button {
                        text(Strings.create)
                        ::enabled { vin().isValidVin() }
                        onClick {
                            try {
                                val result = currentSession().vehicles.insert(
                                    Vehicle(
                                        seller = currentDealershipId() ?: return@onClick,
                                        vin = vin(),
                                    )
                                )() ?: return@onClick
                                navigator.replace(EditVehicleScreen(result._id))
                            } catch (e: Exception) {
                                alert(Strings.error, Strings.thereWasAnErrorCreatingTheVehicle)
                            }
                        }
                    }
                }
            }
            return
        }
        lateinit var cameraPermissions: Readable<Boolean>
        val uploading = Property(false)
        suspend fun captureAndAdvanceAny(image: ImageLocal) {
            val assignedStage = stage.value ?: return
            images[assignedStage] = ImageToBe(local = image).also { it.tryUpload(currentSession()) }
            if(assignedStage == PhotoTakeStage.Vin && vin.value.isBlank())
                vin.value = vinTextEntry() ?: ""
            advance()
        }
        stack {
            spacing = 0.px

            var captureAndAdvanceStage: suspend () -> Unit = {}
            cameraPreview {
                cameraPermissions = hasPermissions
                visible = false
                ::visible { cameraPermissions.await() }
                fun onVinCandidate(result: String) {
                    // First, remove leading "I" for 18 digit "import" VINs
                    val fixedA = if (result.length == 18) result.substring(1) else result
                    if (fixedA.length != 17) return
                    val fixedB = fixedA.parseAsVin() ?: return

                    if (stage.value == PhotoTakeStage.Vin) {
                        println("Captured the vin!")
                        vin.value = fixedB
                    }
                }
                onBarcode { barcodeResult, _ ->
                    barcodeResult.forEach { onVinCandidate(it) }
                }
                onOCR { ocrResult, _ ->
                    ocrResult.split('\n', ' ').forEach { onVinCandidate(it) }
                }
                stack@ captureAndAdvanceStage = label@{
                    captureAndAdvanceAny(capture() ?: return@label)
                }
            }
            image {
                scaleType = ImageScaleType.Fit
                ::source { stage.await()?.image }
            }
            padded - stack {
                ::exists { stage.await() != null }
                gravity(Align.Center, Align.Start) - card - stack {
                    h4 {
                        ::content  { stage.await()?.instructions ?: "" }
                    }
                }
                gravity(Align.Center, Align.Center) - card - col {
                    ::exists { !cameraPermissions.await() }
                    text("Since you haven't enabled your camera, choose from below:")
                    button {
                        text("Use System Camera")
                        onClick label@{
                            val image = ExternalServices.requestCaptureEnvironment()?.let(::ImageLocal) ?: return@label
                            captureAndAdvanceAny(image)
                        }
                    }
                    button {
                        text("Pick from Gallery")
                        onClick label@{
                            val image = ExternalServices.requestFile(listOf("image/png", "image/jpeg"))?.let(::ImageLocal) ?: return@label
                            captureAndAdvanceAny(image)
                        }
                    }
                }
                val disallowVinTake = Property(false)
                reactiveSuspending {
                    disallowVinTake.value = false

                    if (stage.await() == PhotoTakeStage.Vin && vin.await().isBlank()) {
                        disallowVinTake.value = true
                        delay(10_000L)
                        disallowVinTake.value = false
                    }
                }
                gravity(Align.Stretch, Align.End) - col {
                    exists = false
                    ::exists { cameraPermissions.await() }
                    onlyWhen(false) {
                        stage.await() == PhotoTakeStage.Vin && vehicleId == nullUuid
                    } - stack {
                        dynamicTheme {
                            if (vin.await().isNotBlank())
                                AffirmativeSemantic
                            else if (disallowVinTake.await())
                                DangerSemantic
                            else
                                null
                        }
                        text {
                            ::content {
                                if (vin.await().isNotBlank())
                                    Strings.vINScannedTakePictureWhenReady
                                else if (disallowVinTake.await())
                                    Strings.holdSteadyToScanVIN
                                else
                                    Strings.takeAPictureToEnterVINManually
                            }
                        }
                    }
                    centered - important - button {
                        col {
                            icon(Icon.done.copy(width = 4.rem, height = 4.rem), Strings.takePicture)
                        }
                        ::enabled { !disallowVinTake.await() }
                        onClick {
                            captureAndAdvanceStage()
                        }
                    }
                }
            }

            val currentlyUploading = Property<PhotoTakeStage?>(null)
            suspend fun upload() {
                val session = currentSessionNullable.await() ?: throw Exception("Missing session")
                val seller = currentDealershipId.await() ?: throw Exception("Missing dealership")
                // OK, time to upload.
                val allImages = images.mapValues {
                    currentlyUploading.value = it.key
                    it.value.upload(session)
                }
                // Images uploaded, time for the vehicle itself.
                currentlyUploading.value = null

                val result = if (vehicleId == nullUuid) {
                    currentSessionNullable.awaitNotNull().vehicles.insert(
                        Vehicle(
                            seller = seller,
                            vin = vin.value.takeUnless { it.isBlank() } ?: "",
                            exterior = allImages[PhotoTakeStage.Exterior],
                            vinImage = allImages[PhotoTakeStage.Vin],
                            interior = allImages[PhotoTakeStage.Interior],
                            odometerImage = allImages[PhotoTakeStage.Dashboard],
                        )
                    )() ?: throw IllegalStateException("Vehicle insert rejected")
                } else {
                    currentSessionNullable.awaitNotNull().vehicles.get(vehicleId).modify(
                        modification {
                            vin.value.takeUnless { it.isBlank() }?.let { v ->
                                it.vin assign v
                            }
                            allImages[PhotoTakeStage.Exterior]?.let { f -> it.exterior assign f }
                            allImages[PhotoTakeStage.Vin]?.let { f -> it.vinImage assign f }
                            allImages[PhotoTakeStage.Interior]?.let { f -> it.interior assign f }
                            allImages[PhotoTakeStage.Dashboard]?.let { f -> it.odometerImage assign f }
                        }
                    ) ?: throw IllegalStateException("Vehicle modification rejected")
                }
                // Success! Move on.
                navigator.replace(EditVehicleScreen(result._id).apply {
                    if(allImages.isEmpty())
                        tab.value = EditVehicleScreen.Tab.Images
                    else
                        tab.value = EditVehicleScreen.Tab.BasicInformation
                    flowMode.value = true
                })
            }

            centered - card - col {
                ::exists { stage.await() == null }
                text(Strings.uploadingYourVehicle)
                activityIndicator()
                reactiveScope {
                    if (stage() == null) {
                        launch {
                            try {
                                upload()
                            } catch (e: Exception) {
                                e.report()
                                alert(Strings.uploadFailed, "Failed to upload your images.")
                                navigator.navigate(DashboardScreen())
                            }
                        }
                    }
                }
                text {
                    ::content {
                        "Uploading ${currentlyUploading()?.text ?: "vehicle"}..."
                    }
                }
            }
        }
    }
}

@Serializable

enum class PhotoTakeStage(
    val text: String,
    val instructions: String,
    val image: ImageSource,
    val property: SerializableProperty<Vehicle, ServerFile?>
) {
    Exterior(
        "Exterior", Strings.takeAPictureOfYourVehicleAtA, image = vec(
            "M -50,0 h 250 v 150 h -250 z",
            "m 105.15473,109.21546 a 5.0018632,16.583239 0 0 1 -5.00186,16.58323 5.0018632,16.583239 0 0 1 -5.001865,-16.58323 5.0018632,16.583239 0 0 1 5.001865,-16.583244 5.0018632,16.583239 0 0 1 5.00186,16.583244 z",
            "m 103.83489,109.69366 a 3.3152864,12.545662 0 0 1 -3.31529,12.54566 3.3152864,12.545662 0 0 1 -3.315284,-12.54566 3.3152864,12.545662 0 0 1 3.315284,-12.545665 3.3152864,12.545662 0 0 1 3.31529,12.545665 z",
            "m 129.54839,102.45687 -23.87191,11.5092 c 1.17937,-11.40569 0.1732,-15.981077 0.1732,-15.981077 0,0 -0.77606,-7.260254 -3.3943,-8.395227 -2.618252,-1.134973 -4.668631,1.892869 -5.265596,2.720543 -0.596958,0.827675 -2.822864,5.679616 -3.731532,10.323471 -0.908667,4.64387 -1.020248,11.66919 -2.406854,15.07439 0,0 -5.164061,1.28298 -13.13794,1.91122 -7.973873,0.62823 -49.164752,-0.77111 -52.442407,-0.76256 l -5.552379,0.16474 c 0,0 -5.671501,-0.0405 -11.1786143,-1.42489 -0.6212723,-0.11833 -1.4509262,-1.10144 -1.4997044,-2.54475 l -0.2280022,-2.83092 c -0.4129377,-2.62729 -1.2655713,-2.9077 -1.398749,-4.34478 0.1570763,-2.94622 0.4487634,-3.67752 0.4487634,-3.67752 0,0 0.6818793,-1.38927 0.6818793,-1.49368 -0.03831,-0.67337 -0.2044778,-1.7884 -0.025077,-2.868649 0.1794,-1.080246 0.3477899,-0.276652 0.3583718,-1.633005 0.010584,-1.356359 0.087688,-3.00351 0.2281886,-3.527629 0.1405002,-0.524119 0.028331,-0.895267 0.3497929,-1.600925",
            "m 100.96631,125.5787 c -2.287888,1.37301 -2.544678,1.31838 -3.399205,1.38579 -1.632471,0.12878 -10.42391,-0.25713 -10.42391,-0.25713 0,0 -3.461143,-2.14628 -3.953761,-7.62785",
            "m 100.39007,92.638415 c -2.942975,-0.366487 -3.413937,-0.0562 -3.413937,-0.0562",
            "m 60.889511,92.990376 c 0,0 0.16304,1.821785 4.794591,2.618473 12.837529,-2.373611 15.323138,-3.218222 15.323138,-3.218222 0,0 3.967006,-1.065998 6.581679,-3.608686 1.13422,-1.466641 2.541659,-5.47478 2.541659,-5.47478 0,0 0.136221,-0.855028 -0.206841,-1.101247 -0.343069,-0.246226 -1.146058,-0.377238 -1.146058,-0.377238 0,0 -4.415373,0.228865 -6.234824,0.716384 -4.386341,0.995653 -5.805946,1.823276 -13.354195,3.132046 -3.543685,0.614429 -5.911168,3.462506 -8.299149,7.31327 z",
            "m 10.10305,118.03818 c 0.529627,2.26241 3.383854,6.85961 7.273074,6.6425 3.889221,-0.21713 7.518085,-0.24702 8.771393,-0.61361 1.253309,-0.36661 3.594053,-1.85479 4.665497,-5.02387",
            "m 25.781759,124.18237 c -4.446381,-0.64625 -5.599594,-5.18967 -5.646835,-5.19951",
            "m 7.6154033,93.224198 c -0.093431,-1.305022 0.4481931,-5.2188 0.7067437,-5.626066 0.2585493,-0.407272 0.9219602,-1.895241 2.044629,-2.452456 1.122669,-0.557214 1.437176,-0.931603 3.447755,-1.630838 2.01058,-0.699234 3.774273,-1.504198 4.275909,-1.821668 0.501638,-0.317471 3.149211,-2.414004 5.070624,-3.917013 1.921414,-1.503003 2.448886,-1.690033 4.41212,-3.090522",
            "m 13.832919,83.586752 c -0.500479,1.644881 1.202925,2.596791 1.202925,2.596791 l 2.16825,0.872119 0.139339,0.60207 -1.080001,5.444893 c 0,0 -1.125967,2.097338 -1.777857,2.397145 -0.651889,0.299813 -1.443584,0.482169 -1.742232,0.388258 C 12.444695,95.794118 9.175736,94.61119 8.6487062,94.105673 8.121677,93.600162 7.7459183,92.959525 7.7459183,92.959525",
            "m 129.36897,102.75067 c -0.0963,-3.414855 -0.0133,-4.268693 -0.0133,-4.268693 0,0 0.71502,-5.455741 1.85719,-8.812446",
            "m 85.442616,106.89547 a 2.765572,5.2596419 83.71983 0 1 -4.925551,3.32433 2.765572,5.2596419 83.71983 0 1 -5.530606,-2.17363 2.765572,5.2596419 83.71983 0 1 4.925552,-3.32433 2.765572,5.2596419 83.71983 0 1 5.530605,2.17363 z",
            "m 8.6531564,106.73351 a 2.6691293,1.3347209 71.910486 0 1 -0.439978,2.95163 2.6691293,1.3347209 71.910486 0 1 -2.0975201,-2.12276 2.6691293,1.3347209 71.910486 0 1 0.4399781,-2.95164 2.6691293,1.3347209 71.910486 0 1 2.09752,2.12277 z",
            "m 123.37424,105.52908 c 1.85192,6.88489 5.04816,6.09914 5.04816,6.09914 5.15747,-0.27887 5.15747,-0.27887 5.15747,-0.27887 1.41371,-0.52973 2.4153,-2.52016 2.4153,-2.52016",
        ), property = Vehicle_exterior
    ),
    Interior(
        "Interior", Strings.takeAPictureOfTheOpenDriversDoor, image = vec(
            "M -50,0 h 250 v 150 h -250 z",
        ), property = Vehicle_interior
    ),
    Vin(
        "VIN Sticker / Plate", Strings.takeAPictureOfTheVINStickerOn, image = vec(
            "M -50,0 h 250 v 150 h -250 z",
            "M -20,45 h 190 v 60 h -190 z",
        ), property = Vehicle_vinImage
    ),
    Dashboard(
        "Dashboard",
        Strings.takeAPictureOfTheDashboardToShow, image = vec(
            "M -50,0 h 250 v 150 h -250 z",
            "M 133.98835,72.647614 A 58.283272,57.654472 0 0 1 75.705078,130.30209 58.283272,57.654472 0 0 1 17.421806,72.647614 58.283272,57.654472 0 0 1 75.705078,14.993141 58.283272,57.654472 0 0 1 133.98835,72.647614 Z",
            "M 124.77441,72.647614 A 49.069328,49.069328 0 0 1 75.705078,121.71694 49.069328,49.069328 0 0 1 26.63575,72.647614 49.069328,49.069328 0 0 1 75.705078,23.578285 49.069328,49.069328 0 0 1 124.77441,72.647614 Z",
            "M 102.07433,64.322106 c 0,0 -23.487268,-9.76671 -52.733365,0 L 59.710782,103.0228 c 16.34099,5.84928 32.31049,0 32.31049,0 z",
            "M 49.077756,64.425056 c 0,0 -21.284717,12.67541 -21.555839,-1.13086",
            "M 102.31584,64.425056 c 0,0 21.28472,12.67541 21.55584,-1.13086",
            "M 55.491585,87.111766 C 36.265881,81.435226 28.793346,86.013286 28.793346,86.013286",
            "M 96.003765,87.111766 C 115.22947,81.435226 122.702,86.013286 122.702,86.013286",
            "M 111.40293,48.731323 A 10.577907,10.577907 0 0 1 100.82503,59.30923 10.577907,10.577907 0 0 1 90.247121,48.731323 10.577907,10.577907 0 0 1 100.82503,38.153417 a 10.577907,10.577907 0 0 1 10.5779,10.577906 z",
            "M 61.968803,48.731323 A 10.577907,10.577907 0 0 1 51.390903,59.30923 10.577907,10.577907 0 0 1 40.812994,48.731323 10.577907,10.577907 0 0 1 51.390903,38.153417 a 10.577907,10.577907 0 0 1 10.5779,10.577906 z",
            "M 52.721818,48.731323 a 1.330915,1.330915 0 0 1 -1.330915,1.330915 1.330915,1.330915 0 0 1 -1.330915,-1.330915 1.330915,1.330915 0 0 1 1.330915,-1.330915 1.330915,1.330915 0 0 1 1.330915,1.330915 z",
            "M 102.15595,48.731323 a 1.330915,1.330915 0 0 1 -1.33092,1.330915 1.330915,1.330915 0 0 1 -1.330914,-1.330915 1.330915,1.330915 0 0 1 1.330914,-1.330915 1.330915,1.330915 0 0 1 1.33092,1.330915 z",
            "M 51.380839,48.726276 c -4.257834,4.25783 -5.817648,6.04988 -5.817648,6.04988",
            "M 100.83766,48.710286 c -3.441808,3.44181 -5.956058,6.10404 -5.956058,6.10404 v 0",
            "M 68.864053,32.750694 h 13.682055 c 1.908847,0 3.445572,1.536726 3.445572,3.445573 v 5.823792 c 0,1.908847 -1.536725,3.445572 -3.445572,3.445572 H 68.864053 c -1.908848,0 -3.445573,-1.536725 -3.445573,-3.445572 v -5.823792 c 0,-1.908847 1.536725,-3.445573 3.445573,-3.445573 z",
            "M 71.269041,48.952316 h 8.872078 c 1.990602,0 3.593145,1.602543 3.593145,3.593146 0,1.990603 -1.602543,3.593146 -3.593145,3.593146 h -8.872078 c -1.990602,0 -3.593145,-1.602543 -3.593145,-3.593146 0,-1.990603 1.602543,-3.593146 3.593145,-3.593146 z",
            "M 73.690487,43.250816 h -0.250803 l -0.493337,-0.796505 -0.489203,0.796505 h -0.250803 l 0.621495,-0.990808 -0.618739,-0.953602 h 0.259071 l 0.478179,0.770323 0.482313,-0.770323 h 0.248046 l -0.609092,0.953602 z m 1.693607,0 h -0.250802 l -0.493338,-0.796505 -0.489203,0.796505 h -0.250803 l 0.621495,-0.990808 -0.618738,-0.953602 h 0.259071 l 0.478178,0.770323 0.482313,-0.770323 h 0.248047 l -0.609092,0.953602 z m 1.693608,0 h -0.250803 l -0.493337,-0.796505 -0.489203,0.796505 h -0.250803 l 0.621495,-0.990808 -0.618739,-0.953602 h 0.259071 l 0.478179,0.770323 0.482313,-0.770323 h 0.248047 l -0.609093,0.953602 z m 1.693608,0 h -0.250803 l -0.493337,-0.796505 -0.489204,0.796505 h -0.250802 l 0.621494,-0.990808 -0.618738,-0.953602 h 0.259071 l 0.478179,0.770323 0.482313,-0.770323 h 0.248046 l -0.609092,0.953602 z m 1.693608,0 h -0.250803 l -0.493338,-0.796505 -0.489203,0.796505 h -0.250802 l 0.621494,-0.990808 -0.618738,-0.953602 h 0.259071 l 0.478178,0.770323 0.482313,-0.770323 h 0.248047 l -0.609092,0.953602 z m 1.693607,0 h -0.250802 l -0.493338,-0.796505 -0.489203,0.796505 h -0.250803 l 0.621495,-0.990808 -0.618739,-0.953602 h 0.259071 l 0.478179,0.770323 0.482313,-0.770323 h 0.248047 l -0.609092,0.953602 z",
            "M 72.080139,44.479805 h -0.242535 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511253,-0.719338 -0.511253,-0.716582 h 0.246669 l 0.385852,0.558107 0.392742,-0.558107 h 0.228755 l -0.508498,0.708314 z m 1.693614,0 h -0.242536 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511254,-0.719338 -0.511254,-0.716582 h 0.24667 l 0.385852,0.558107 0.392742,-0.558107 h 0.228755 l -0.508498,0.708314 z m 1.693613,0 h -0.242535 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511254,-0.719338 -0.511254,-0.716582 h 0.24667 l 0.385851,0.558107 0.392742,-0.558107 h 0.228755 l -0.508497,0.708314 z m 1.693614,0 h -0.242535 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511253,-0.719338 -0.511253,-0.716582 h 0.246669 l 0.385852,0.558107 0.392742,-0.558107 h 0.228755 l -0.508497,0.708314 z m 1.693614,0 h -0.242536 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511254,-0.719338 -0.511254,-0.716582 h 0.24667 l 0.385852,0.558107 0.392742,-0.558107 h 0.228755 l -0.508498,0.708314 z m 1.693613,0 h -0.242535 l -0.384474,-0.562241 -0.398254,0.562241 h -0.228755 l 0.511253,-0.719338 -0.511253,-0.716582 h 0.246669 l 0.385852,0.558107 0.392742,-0.558107 h 0.228755 l -0.508497,0.708314 z"
        ), property = Vehicle_odometerImage
    );
}

suspend fun ViewWriter.vinTextEntry(): String? = suspendCoroutineCancellable { cont ->
    val title = Strings.manualVINEntry
    val result = Property("")
    val screen = object : KiteUiScreen {
        override val title: Readable<String> = Constant(title)
        override fun ViewWriter.render() {
            dismissBackground {
                onClick {
                    println("Suppress the shit")
                }
                centered - card - col {
                    h2(title)
                    text(Strings.pleaseManuallyEnterInYourVIN)
                    fieldTheme - textField {
                        this.hint = "VIN"
                        keyboardHints = KeyboardHints.id
                        content bind result
                    }
                    row {
                        expanding - space()
                        button {
                            h6("OK")
                            ::enabled { result.await().isValidVin() }
                            onClick {
                                navigator.dismiss()
                            }
                        } in danger
                    }
                }
            }
        }
    }
    dialogScreenNavigator.navigate(screen)
    val job = Job()
    CoroutineScope(job + Dispatchers.Main).reactiveScope {
        if (dialogScreenNavigator.currentScreen() != screen) {
            cont.resume(result.value.takeIf { it.isValidVin() })
            job.cancel()
        }
    }
    return@suspendCoroutineCancellable {
        job.cancel()
    }
}

private fun vec(vararg pathData: String) = ImageVector(
    width = 250.rem,
    height = 150.rem,
    viewBoxMinX = -50,
    viewBoxWidth = 250,
    viewBoxHeight = 150,
    paths = pathData.map {
        ImageVector.Path(
            strokeColor = Color.white,
            strokeWidth = 0.5,
            path = it
        )
    }
)