package com.ilussobsa

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder


abstract class EnumIgnoreUnknownSerializer<T : Enum<T>>(val descriptorName: String, values: Array<out T>, private val defaultValue: T) :
    KSerializer<T> {
    // Alternative to taking values in param, take clazz: Class<T>
    // - private val values = clazz.enumConstants
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor(descriptorName, PrimitiveKind.STRING)
    // Build maps for faster parsing, used @SerialName annotation if present, fall back to name
    private val lookup = values.associateBy({ it }, { it.name })
    private val revLookup = values.associateBy { it.name }

    override fun serialize(encoder: Encoder, value: T) {
        encoder.encodeString(lookup.getValue(value))
    }

    override fun deserialize(decoder: Decoder): T {
        val str = decoder.decodeString()
        return revLookup[str] ?: defaultValue.also { println("WARNING: Unknown ${descriptor.serialName} '$str'") }
    }
}